.footer {
    background-color: #000;
    padding: 40px 0;
    border-top: 10px solid var(--color-primary);
}
.footer .container {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
}
@media only screen and (max-width: 960px) {
    .footer {
        background-color: #000;
        padding: 60px 0 15px 0;
        border-top: 10px solid var(--color-primary);
    }
    .footer .container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: stretch;
        justify-content: stretch;
        width: 100%;
    }
}
.footer .container h4 {
    margin-bottom: 20px;
}
img {
    width: 100px;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.9;
    font-size: 18px;
}
ul li {
    color: var(--color-gray);
}
li svg {
    margin-left: 10px;
    font-size: 20px;
    color: var(--color-primary);
}
.logo-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: 20px;
    text-align: center;
    line-height: 0.8;
}
.logo-section span {
    font-size: 15px;
}
.contact-info {
    flex: 1;
}
.send-email {
    flex: 1;
}
.send-email form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    
}
input, textarea {
    border: none;
    border-radius: 5px;
    background-color: var(--color-light);
    font-size: 14px;
    padding: 5px;
}
input:focus, textarea:focus {
}
.send-email form button {
    border: none;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: #fff;
    padding: 5px 10px;
    margin-top: 5px;
}