nav {
    background-color: var(--color-light);
    text-wrap: nowrap;
    margin-top: 5px;
}
.navbar-brand span {
    font-size: 10px;
}
@media only screen and (max-width: 540px) {
    nav {
        background-color: var(--color-light);
        text-wrap: nowrap;
        margin-top: 0px;
        }
}
.navbar-collapse li {
    margin-right: 25px;
}
.logo {
    width: 60px;
    height: 60px;  
}
.logo-info {
    line-height: 0.2;
    font-size: 14px;
}
select {
    margin: 0 10px;
    background-color: transparent;
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}
select .langOption {
    text-align: center;
    margin: 0;
    cursor: pointer;
    background-color: antiquewhite;
}
select .langOption:focus {
    background-color: transparent;
}