/* Start landing page */
.hompage-background {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/1699445124083.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--color-white);
}
.hompage-background img {
    z-index: 2;
    width: 250px;
    margin-top: 120px;
}
.hompage-background h1 {
    font-weight: bold;
    font-size: 3.7rem;
    z-index: 2;
}
.slogen {
    text-align: center;
    margin-top: 20px;
    border-right: 2px  solid var(--color-primary);
    border-left: 2px  solid var(--color-primary);
    padding: 10px;
    border-radius: 16px;
}
.hompage-background .buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    z-index: 2;
}
.hompage-background span {
    margin-top: 20px;
    padding-bottom: 5px;
    font-size: 25px;
    z-index: 2;
    text-align: center;
}
.hompage-background::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #033f84;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 1;
}
.hompage-background p {
    font-size: 30px;
    z-index: 2;
}
@media only screen and (max-width: 767px){
    .hompage-background h1 {
        font-size: 2rem;
    }
    .hompage-background p {
        font-size: 20px;
        z-index: 2;
    }
    .hompage-background img {
        z-index: 2;
        width: 230px;
        margin-top: 80px;
    }
}
/* End landing page */
/* start About Us section */
.about-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.about-button {
    display: flex;
    gap: 10px;
}
.about-info p {
    margin-bottom: 25px;
}
@media only screen and (max-width: 760px) {
    .about-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .about-button {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    .about-button a {
        margin-left: 10px;
    }
    .slogen {
        width: 90%;;
    }
}


/* End About Us section */
/* start why Us section */
.why-us-section {
    background-image: url('../../assets/images/pexels-katrin-bolovtsova-6077326.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-clip: content-box;
    color: #fff;
    text-align: center;
}
@media only screen and (max-width: 760px) {
    .why-us-section {
        background-size: cover;
    }
}
/* end why Us section */
/* start why us2 */
.why-us2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1100px) {
    .why-us2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .why-us2 img {
        border-right: none;
    }
}
.disc-item {
    position: relative;
}

.disc-item div svg {
    width: 1.5rem;
    height: 1.5rem;
}
.disc-item p {
    color: var(--color-gray);
}
@media only screen and (min-width: 1100px) {
    .disc-item div {
        position: absolute;
        top: 0;
        background-color: var(--color-primary);
        color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .disc-item p {
        color: var(--color-gray);
        padding-left: 50px;
        padding-right: 50px;
    }
    .disc-item h3 {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media only screen and (max-width: 1100px) {
    .disc-item {
        border: 1px solid var(--color-primary);
        margin-bottom: 10px;
        padding-right: 5px;
        padding-top: 30px;
        border-radius: 15px;
    }
    .disc-item div {
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
        left: 50%;
        background-color: var(--color-primary);
        color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .disc-item p {
        color: var(--color-gray);
        padding-left: 10px;
    }
    .disc-item h3 {
        padding-left: 10px;
    }
}
/* end why us2 */
/* start our services section */
.our-services {
    background-color: var(--color-dark);
    color: #fff;
}
.card-holder {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: stretch;
}
.card-holder .serv-card {
    flex: 1;
}
@media only screen and (max-width: 960px) {
    .card-holder {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
    .card-holder .serv-card {
        width: 100%;    
    }
}
/* start carousel section */
/* end carousel section */
.carousel-section {
    height: 700px;
    width: 100vw;
    overflow: hidden;
} 
@media only screen and (max-width: 540px) {
    .carousel-section {
        height: 200px;
        width: 100vw;
        overflow: hidden;
    } 
}
.carousel-section img {
    height: 100%;
}
/* end our services section */
/* start our works section */
.our-works {
    background-color: var(--color-light);
}
/* end our works section */


